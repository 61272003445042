import React, { useState, useEffect, useContext } from "react";
import Select from 'react-select';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import useFavoritesStore from '../hooks/useFavoritesStore';
import Loading from '../components/Loading';
import Card from '../components/Card';
import ModalDetailStore from '../components/ModalDetailStore';
import * as bootstrap from 'bootstrap';
import ToastModal from "../components/ToastModal";
import $ from 'jquery';
import UserContext from "../context/useLoginContext";
import '@styles/Favorites.scss';

const Favorites = () => {
    const [categories, setCategories] = useState([]);
    const [categoryValue, setCategoryValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isdashPage, setIsdashPage] = useState(true);
    const [category, setCategory] = useState(null);
    const [storesList, setStoreslist] = useState([]);
    const [dataStore, setDataStore] = useState(null);
    const [messageToast, setMessageToast] = useState(null);
    const { user } = useContext(UserContext);
    const [storefavorite, setstoreFavorite] = useState(null);

    const fetchDataStoreFavorite = () => {
        try {
            if(storefavorite){
                setIsdashPage(false)
                fetchData();
            }
        } catch (error) {
            console.error("Error fetching store when you set favorite store:", error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchDataStoreFavorite();
    }, [storefavorite]);

    const fetchData = async () => {
        try {
            if (user && storesList.length === 0 && categories.length === 0) {
                await processDataSearch('available');
            }
        } catch (error) {
            console.error("Error fetching store when user is logged:", error);
            setMessageToast("¡Lo sentimos! En este momento no hay establecimientos disponibles para la busqueda seleccionada.")
            if (messageToast) {
                const toastEl = $('#liveToast')[0];
                if (toastEl) {
                    let toast = new bootstrap.Toast(toastEl, { delay: 5000 });
                    toast.show();
                }
            }
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [user]);

    const processDataSearch = async(filter) =>{
        setLoading(true);
        const { stores, categories:categories_ } = await useFavoritesStore(filter);
        if(filter === 'available'){
            setCategory(null)
            setCategories(categories_);
        }
        setStoreslist(stores);
        setLoading(false);
        setIsdashPage(true)
    }

    const handleChange = async(selectedOption) => {
        setIsdashPage(false)
        setCategory(selectedOption)
        await processDataSearch(selectedOption.value)
    };

    return (
        <div className="Favorites">
            <div className='Content'>
                <div className="title">Mis favoritos</div>
                <div className='filters'>
                    <div className="search-carrousel">
                        <div className="select-search">
                            <Select
                                value={category}
                                className="select-input"
                                classNamePrefix="select-input"
                                options={categories}
                                placeholder="Seleccionar el servicio..."
                                defaultValue={category}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="content-detail">
                    {
                        storesList.map((item, index) => (
                            <Card
                                name={item.name}
                                id={item.id}
                                lat={item.lat}
                                lng={item.lng}
                                category={item.categoryName}
                                categoryValue={item.categoryValue}
                                setCategoryValue={setCategoryValue}
                                reference={item.reference}
                                image={item.image}
                                key={index}
                                storeId={item.id}
                                setDataStore={setDataStore}
                                isFavorite={item.isFavorite}
                                setstoreFavorite={setstoreFavorite}
                                setLoading2={setLoading}
                                isfavoritePage={true}
                                setIsdashPage={setIsdashPage}
                            />
                        ))
                    }
                </div>
            </div>
            <ModalDetailStore
                dataStore={dataStore}
                setDataStore={setDataStore}
                categoryValue={categoryValue}
                setLoading2={setLoading}
                setstoreFavorite={setstoreFavorite}
                setIsdashPage={setIsdashPage}
            />
            <ToastModal message={messageToast}/>
            {
                loading && <Loading  isdashPage={isdashPage}/>
            }
        </div>
    );
}

export default Favorites;
